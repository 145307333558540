<template>
  <y_layout>
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">
      <span class="y-desc">弃用此方式</span>
      <el-form :model="form" label-width="8rem" style="padding: 2rem">
        <el-form-item label="订单状态更新通知">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="成员加入提醒">
          <el-input v-model="form.location"></el-input>
        </el-form-item>
        <el-form-item >
          <el-button type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
export default {
  name: "index",
  components:{y_layout},
  data(){
    return{
      form:{}
    }
  }
}
</script>

<style scoped>

</style>